import { commonService } from './common.services.js';
export const dashboardActions = {
    getSuperAdminDashboard
};


// Get super admin dashboard 
function getSuperAdminDashboard(apiName, codeData) {
    return commonService.getDataWithToken(apiName, codeData).then(
      (response) => {
        return response.data;
      }
    );
}