import { Formik, Field, ErrorMessage } from 'formik'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { getAllTeams, inviteAdmins } from '../../Store/teamsSlice'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

function InviteAdmin({ isOpen, setIsOpen,role}) {
  const dispatch = useDispatch()
  const toggle = () => setIsOpen(!isOpen)
const [isButtonDisabled, setIsButtonDisabled]=useState(false);

  return (
    <>
      <Modal size="sm" isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('invite')}{role} </ModalHeader>
        <ModalBody>
          <Formik
         
            initialValues={{ firstName: '', lastName: '', email: '',isAdmin:'',isSuperAdmin:'' }}
          
            validate={(values) => {
              const errors = {}
              if (!values.firstName) {
                errors.firstName = t('required')
              }
              else
              {
                if( !/^[A-Z]/.test(values.firstName))
                {
                  errors.firstName=t('FirstLetterCapital')
                }
              }
              if (!values.lastName) {
                errors.lastName = t('required')
              }
             
              if (!values.email) {
                errors.email = t('required')
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = t('invalidEmailAddress')
              }
              return errors
            }}
            onSubmit={ async(values, { setSubmitting }) => {
              if(role === 'Admin') {
                values.isAdmin=true;
                values.isSuperAdmin=false;
              }else if(role === 'SuperAdmin') {
                values.isAdmin=false;
                values.isSuperAdmin=true;
              }else if(role === 'Assessor'){
                values.isAdmin=false;
                values.isSuperAdmin=false;
              }
              setSubmitting(true)
              // setIsButtonDisabled(!isButtonDisabled);

              await dispatch(inviteAdmins(values))
              const obj={
                offset:1,
                limit:10,
                query:''
              }
              await dispatch(getAllTeams(obj))
              toggle()
            }}
          >
            {({ handleSubmit ,isSubmitting}) => (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {t('fName')}
                  </label>
                  <Field
                    data-testid="enter-fname"
                    type="text"
                    name="firstName"
                    placeholder={t('enterFName')}
                    className={
                      'mt-1 block w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5'
                    }
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="text-red-500 mt-2 text-sm"
                  />
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {t('lName')}
                  </label>
                  <Field
                    data-testid="enter-lname"
                    type="text"
                    name="lastName"
                    placeholder={t('enterLName')}
                    className={
                      'mt-1 block w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5'
                    }
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="text-red-500 mt-2 text-sm"
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {t('email')}
                  </label>
                  <Field
                    type="email"
                    name="email"
                    data-testid="enter-email"
                    placeholder={t('enterEmailp')}
                    className={
                      'mt-1 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 mt-2 text-sm"
                  />
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`w-full text-white bg-blue-700  ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                  } hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5`}
                  >
                  
                  {isSubmitting ? 'Submitting...' : t('submit')}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  )
}

export default InviteAdmin
