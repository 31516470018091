import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiConstants } from '../Constants/api.constant.js';
import { dashboardActions } from '../Services/dashboard.service.js';


export const getSuperAdminDashboard = createAsyncThunk('/getSuperAdminDashboard', async (data) => {
  const response = await dashboardActions.getSuperAdminDashboard(apiConstants.GET_SUPER_ADMIN_DASHBOARD, data);
  if(response && response.status){
    // console.log(response)
  }
  return response;
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    loading: false,
    error: null,
    superAdminDashboard: null
  },
  reducers: {
    changeValue: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSuperAdminDashboard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSuperAdminDashboard.fulfilled, (state, action) => {
        state.loading = false;
        state.superAdminDashboard = action.payload;
      })
      .addCase(getSuperAdminDashboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});


export const { changeValue } = dashboardSlice.actions;
export default dashboardSlice.reducer 