/* eslint-disable no-debugger */
/* eslint-disable no-useless-computed-key */
/* eslint-disable no-constant-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useMemo } from 'react'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { apiConstants } from '../../Constants/api.constant.js'
import { commonService } from '../../Services/common.services.js'
import { RiDeleteBin3Fill } from 'react-icons/ri/index.js'
import { CSVLink } from 'react-csv'
import { RxCrossCircled } from 'react-icons/rx';
import { ImCross } from 'react-icons/im';
import { formatedDate } from '../../Utils/Index.js'

import { AiFillCheckCircle } from 'react-icons/ai/index.js'
import DataTable from '../Layouts/DataTable.js'
import {
  getCandidate,
  updateAssessmentById,
} from '../../Store/assessmentSlice.js'
import debounce from 'lodash.debounce'
import { BiSearch } from 'react-icons/bi/index.js'
import DeleteAssessment from '../Modal/DeleteAssessment.js'
import Breadcrumbs from '../Common/Breadcrumbs.js'
import { TbProgressCheck } from 'react-icons/tb'
import { MdOutlinePending } from 'react-icons/md'
import { t } from 'i18next'
import { BsWhatsapp } from 'react-icons/bs'
import { FaShareSquare } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'
import useOnClickOutside from '../../CustomHooks/index.js'
import SendMail from '../Modal/SendMail.js'
import { FiEdit } from 'react-icons/fi'
import EditModal from '../Modal/EditModal.js'
import toast from 'react-hot-toast'
import { getAllTeams } from '../../Store/teamsSlice.js'
import SwitchButton from '../Common/SwitchButton.js'
import Loader from '../Common/Loader.js'
import { images } from '../../Constants/image.constant.js'
import { appConstants } from '../../Constants/app.constant.js'

const AssessmentDetails = () => {
  const candidateData = useSelector((state) => state.assessment.getCandidates)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const handleSelectedIds=()=>{
    setSelectedIds([])
  }
  const status = [
    { id: 1, status: 'In_Progress' },
    { id: 2, status: 'Invited' },
    { id: 3, status: 'Completed' },
    { id: 4, status: 'Waiting' }
  ]
  const scoreStatus = [
    { id: 1, status: 'Equals To' },
    { id: 2, status: 'Greater Than' },
    { id: 3, status: 'Less Than' },
    { id: 4, status: 'Between' },
  ]
  const percStatus = [
    { id: 1, status: 'Equals To' },
    { id: 2, status: 'Greater Than' },
    { id: 3, status: 'Less Than' },
    { id: 4, status: 'Between' },
  ]
  const navigate = useNavigate()
  const { state } = useLocation()
  const locatData = useSelector((state) => state.candidates)
  const isBulkDelete = locatData?.loading
  const [assesmentData, setAssessmentData] = useState(null)
  const [candidates, setCandidates] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [offset, setOffset] = useState(
    state?.candidateOffset ? state?.candidateOffset : 1
  )
  const [filterByPerc, setFilterByPerc] = useState(
    state?.score === '<50' ? 'Less Than' : state?.score === '>91' ? 'Greater Than' : state?.score === '50 - 75' || state?.score === '76 - 90' ? 'Between' : ''
  )
  const [filterByScore, setFilterByScore] = useState('')
  const [showInputField, setShowInputField] = useState(false);//perc
  const [showMaxInputField, setShowMaxInputField] = useState(false);//perc
  const [showInputFieldScore, setShowInputFieldScore] = useState(false);//perc
  const [showMaxInputFieldScore, setShowMaxInputFieldScore] = useState(false);//perc

  // const [rating, setRating] = useState(null)
  // const [hover, setHover] = useState(null)
  const [search, setSearch] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [candidateId, setCandidateId] = useState(null)
  // const [isCopy, setIsCopy] = useState(false);
  const { _id, assessment_url } = useParams()
  var assessment_id = _id
  // const [text, setText] = useState('');
  const [isShare, setIsShare] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [filedName, setFiledName] = useState('')
  const [teamsList, setTeamsList] = useState([])
  const [value, setValue] = useState('')
  const [filterByStatus, setFilterByStatus] = useState(state?.status ? state?.status : '')
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false)
  const [showScore, setShowScore] = useState(assesmentData?.showScore)
  const [isNegativeMarking, setIsNegativeMarking] = useState(assesmentData?.isNegativeMarking)
  const [minScore, setMinScore] = useState(0);
  const [maxScore, setMaxScore] = useState(0)
  const [minPerc, setMinPerc] = useState(0);
  const [maxPerc, setMaxPerc] = useState(0)
  const [isDropdownShow, setIsDropdownShow] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [submenu2Open, setSubmenu2Open] = useState(false);
  const [submenu3Open, setSubmenu3Open] = useState(false);
  // const [isCandidateLoading, setCandidateLoading] = useState(false)
  const [skillNames, setSkillNames] = useState([]);
  const teamsData = useSelector((state) => state.teams)
  const [limit, setLimit] = useState(appConstants.LIMIT)

  const [selectedIds, setSelectedIds] = useState([])
  const [isAllSelect, setIsAllSelect] = useState(false)
  const [isShowBulkDelete,setIsShowBulkDelete]= useState(false);


  const dispatch = useDispatch()
  const ref = useRef()
  const filterRef = useRef()
  // const limit = appConstants.LIMIT
  const breadcrumbItems = [
    { label: t('assessment'), url: '/assessments' },
    {
      label: t('details'),
      url: `/assessments/${_id}/${assessment_url}`,
    },
  ]



  useEffect(() => {
    let obj = {
      offset: 1,
      limit: limit,
      query: search,
    }
    dispatch(getAllTeams(obj))
  }, [])

  useEffect(() => {
    // Update component state when teams data changes
    if (teamsData.getAllTeams && teamsData.getAllTeams.status) {
      setTeamsList(teamsData?.getAllTeams?.users)
    }
  }, [teamsData?.getAllTeams])

  const handlePageClick = (page) => {
    setOffset(page.selected + 1)
  }
  const handleLimit = (e) => {
    Number(e.target.value) === 0 ? setLimit(10) : setLimit(Number(e.target.value))
  }
  const handleFilterByStatus = (e) => {
    setFilterByStatus(e)
    setSearch('')
    setIsDropdownShow(false);
    setSubmenu2Open(false);
    setSubmenuOpen(false)
    setSubmenu3Open(false);
    // setIsLoadingRefresh(true)


  }
  const handleFilterByPerc = (e) => {
    setFilterByPerc(e);
    setSearch('');
    if (e === 'All') {
      setFilterByPerc(e);

      setShowInputField(false)
      setShowMaxInputField(false)
    }
    else if (e === 'Between') {
      setShowInputField(true)

      setShowMaxInputField(true);
    }
    else {
      setShowInputField(true)

      setShowMaxInputField(false);

    }
    // setShowMaxInputField(false);
    // setIsLoadingRefresh(true)
  }
  const handleFilterByScore = (e) => {
    setFilterByScore(e);
    setSearch('');
    if (e === 'All') {
      setFilterByScore(e);

      setShowInputFieldScore(false)
      setShowMaxInputFieldScore(false)
    }
    else if (e === 'Between') {
      setShowInputFieldScore(true)

      setShowMaxInputFieldScore(true);
    }
    else {
      setShowInputFieldScore(true)

      setShowMaxInputFieldScore(false);

    }
    // setShowMaxInputField(false);
    // setIsLoadingRefresh(true)
  }
  const handleCandidateDelete = (candidateId) => {
    if (candidateId) {
      setCandidateId(candidateId)
      setIsOpen(!isOpen)
    }
  }
  const handleRowClick = (data) => {
    const { _id, status } = data
    if (status === 'Completed') {
      navigate('/assessments/candidateDetails/' + _id, {
        state: {
          offset: state?.offset,
          candidateOffset: offset,
          assessment_id: assessment_id,
          assessment_url: assessment_url,
          percentile:data.percentile,
          isNegativeMarking:isNegativeMarking
        },
      })
    } else {
      toast.error('This test has not been completed yet.')
    }
  }
  const handleEditModalClick = (value, filedName) => {
    setIsModalOpen(!isModalOpen)
    setFiledName(filedName)
    setValue(value)
  }

  const handleApprovedStatusChange = async (e, id) => {
    let tempCandidateList = candidates.map((candidate) => ({ ...candidate }))
    let findIndex = tempCandidateList.findIndex((data) => data._id === id)

    if (findIndex > -1) {

      let obj = {
        candidateId: id,
      }

      let response = await commonService.withToken(
        apiConstants.CANDIDATE_APPROVE,
        obj
      )
      if (response?.data && response?.status && response?.data?.status) {
        let obj = {
          assessment_url: assessment_url,
          assessment_id: _id,
          offset: offset,
          limit: limit,
          query: search,
          filter: {
            status: filterByStatus,
          },
        }
        tempCandidateList[findIndex] = {
          ...tempCandidateList[findIndex],
          status: 'Invited',
        }
        setCandidates(tempCandidateList)
        toast.success('Candidate Invite successfull!')
        await dispatch(getCandidate(obj))
      } else {
        toast.error(response?.data?.message)
      }
    }
  }


  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setIsAllSelect(true)
    } else {
      setIsAllSelect(false)
    }
  }

  const handleRowChange = (event, id) => {
    event.stopPropagation()
    const index = selectedIds?.indexOf(id)
    if (index === -1) {
      setSelectedIds([...selectedIds, id])
    } else {
      setSelectedIds(selectedIds.filter((_id) => _id !== id))
    }
  }

  useEffect(() => {
    if (isAllSelect) {
      setSelectedIds(candidates?.map((row) => row._id))
    } else {
      setSelectedIds([])
    }
  }, [isAllSelect])

  // const copyToClipBoard = () => {
  //   navigator.clipboard
  //     .writeText(text)
  //     .then(() => {
  //       setIsCopy(true);
  //       toast.success(t('copiedURL'), {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     })
  //     .catch((error) => {
  //       // Handle clipboard writeText error
  //       console.error('Clipboard writeText failed:', error);
  //       setIsCopy(false);
  //     });
  //   // () => {
  //   //     setIsCopy(false)
  //   // });

  //   setTimeout(() => {
  //     setIsCopy(false);
  //   }, 5000);
  // };

  const columns = [
    {
      Header: (
        <div className="w-100">
          <input
            type="checkbox"
            checked={isAllSelect}
            ref={(input) => {
              if (input) {
                input.indeterminate =
                  selectedIds?.length > 0 &&
                  candidates.length !== selectedIds.length
              }
            }}
            className="d-block w-100 cursor-pointer"
            onChange={(event) => handleSelectAllChange(event)}
          />
        </div>
      ),
      accessor: '_id',
      disableSortBy: true,
      Cell: ({ row }) => (
        <div onClick={(event) => event.stopPropagation()}>
          <input
            type="checkbox"
            checked={selectedIds.includes(row.original._id)}
            onChange={(event) => handleRowChange(event, row.original._id)}
            className={`cursor-pointer ${
              selectedIds.length ? 'showcheckbox' : 'cardcheckbox'
            }`}
          />
        </div>
      ),
    },
    { Header: t('name'), accessor: 'candidateName' },
    { Header: t('email'), accessor: 'candidateEmail' ,
      Cell:({row})=>(
        <div title={row?.values?.candidateEmail}>
          {row?.values?.candidateEmail.length>18 ? row?.values?.candidateEmail.slice(0,18)+'...' : row?.values?.candidateEmail}
        </div>
      )
    },
    {
      Header: `Score(out of ${assesmentData?.totalTestScore})`, accessor: 'gathered_score',
      Cell: ({ row }) => (
        <div className='flex justify-center '>
          {row?.values?.gathered_score}
        </div>
      )
    },
    {
      Header: t('Percentage'),
      accessor: 'candidatePercentage',

      Cell: ({ row }) => (
        <div className="flex items-center justify-center">
          {row.values?.candidatePercentage === 'NA' ? row.values?.candidatePercentage : `${row.values?.candidatePercentage}%`}
        </div>
      ),
    },
    {
      Header: t('Percentile'),
      accessor: 'percentile',

      Cell: ({ row }) => (
        <div className="flex items-center justify-center">
          {row.values?.percentile}
        </div>
      ),
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: true,
      Cell: ({ row }) => (
        <div
          className=" sm:px-1 flex items-center justify-center"
          onClick={(event) => event.stopPropagation()}
        >
          {row.values?.status === 'In_Progress' ? (
            <TbProgressCheck className="text-secondary text-lg" title={row.values?.status} />
          ) : row.values?.status === 'Invited' ? (
            <MdOutlinePending className="text-primary text-lg" title={row.values?.status} />
          ) : row.values?.status === 'Waiting' ? (
            <>
              {/* <ImSpinner3 className="text-primary text-lg" /> */}
              {row?.original?.status === 'Waiting' && (
                <SwitchButton
                  data={row?.original?.status === 'Waiting' ? false : true}
                  status={row?.original?.status === 'Waiting' ? 0 : 1}
                  handleStatusChange={(e) =>
                    handleApprovedStatusChange(e, row?.original?._id)
                  }
                  title={row.values?.status}
                />
              )}
            </>
          ) : (
            <AiFillCheckCircle className="text-success text-lg" title={row.values?.status} />
          )}

          {/* {<span className={`absolute z-10 top-75 scale-0 rounded border-1 bg-light border-gray-400 p-1 text-xs text-black group-hover:scale-100 ${row?.original?.status === 'Waiting'? 'top-[10rem]': 'top-75'} 
         `}
         >
            {row.values?.status}
          </span>} */}
        </div>
      ),
    },
    {
      Header: t('action'),
      accessor: 'action',
      disableSortBy: true,
      Cell: ({ row }) => (
        <div className="flex items-center justify-center">
          <RiDeleteBin3Fill
            className="text-red-600 text-lg cursor-pointer"
            onClick={(e) => {
              e.stopPropagation()
              handleCandidateDelete(row.original._id)
            }}
          />
          {/* <button className=" bg-primary text-white rounded px-2 text-center cursor-pointer text-base">Details</button> */}
        </div>
      ),
    },
  ]

  const handleSubmitBulkDelete = async () => {
   

    try {
      setIsShowBulkDelete(true)
      setIsOpen(!isOpen)
    } catch (err) {
      console.error('err', err)
    }
  }


  // const searchRes = useCallback(
  //     debounce(async (query) => {
  //         try {
  //             let obj = {
  //                 assessment_url: assessment_url,
  //                 assessment_id: _id,
  //                 offset: 1,
  //                 limit: 10,
  //                 query: query
  //             }

  //             dispatch(getCandidate(obj))
  //         } catch (error) {
  //             console.error('Error performing search:', error);
  //         }
  //     }, 500),
  //     []
  // );

  useEffect(() => {
    const debouncedGetData = debounce(async () => {
      // setCandidateLoading(true)
      let obj = {
        assessment_url: assessment_url,
        assessment_id: _id,
        offset: offset,
        limit: limit,
        query: search,
        filter: {
          status: filterByStatus,
        },
        filterScore: filterByScore,
        filterPerc: filterByPerc,
        minScore: Number(minScore),
        maxScore: Number(maxScore),
        minPerc: Number(minPerc),
        maxPerc: Number(maxPerc)
      }
      setIsLoadingRefresh(true)
      await dispatch(getCandidate(obj))

    }, 300) // Example debounce with 300ms delay

    debouncedGetData() // Initial call

    return () => {
      // Cleanup function
      // This will be called before the next effect is executed or when the component unmounts
      debouncedGetData.cancel() // Cancel any pending API call on cleanup
    }
  }, [dispatch, offset, limit, search, filterByStatus, minScore, maxScore, filterByScore, filterByPerc, minPerc, maxPerc])

  useEffect(() => {
    // setIsAssessmentLoading(true)
    const obj = {
      assessment_id: _id,
      limit: limit,
      offset: offset,
    }

    const getData = async () => {
      try {
        setIsLoading(true)
        const response = await commonService.withToken(
          apiConstants.GET_ASSESSMENT_BY_ID,
          obj
        )
        if (response?.data && response?.status) {
          setAssessmentData(response?.data?.assesment)
          const questionBanks = response?.data?.assesment?.questionBanksId.map((questions) => (
            questions.qBankName
          ))
          setSkillNames(questionBanks)
          setShowScore(response?.data?.assesment?.showScore)
          setIsNegativeMarking(response?.data?.assesment?.isNegativeMarking)
          setIsLoading(false)
          // setText(response?.data?.assesment?.assessment_invite_url);
        }
        return response
      } catch (error) {
        // Handle error
        console.log(error)
      }
    }

    const debouncedGetData = debounce(getData, 0) // Example debounce with 300ms delay

    debouncedGetData() // Initial call

    return () => {
      // Cleanup function
      // This will be called before the next effect is executed or when the component unmounts
      debouncedGetData.cancel() // Cancel any pending API call on cleanup
    }
  }, [])

  useEffect(() => {
    if (candidateData && candidateData.candidates) {
      setCandidates(candidateData?.candidates)
      setPageCount(candidateData?.pages)
      setIsLoading(false)
      setIsLoadingRefresh(false)
    }

  }, [candidateData])
  // skillNames.map((skill=> console.log(skill)))
  useOnClickOutside(ref, () => {

    setIsShare(false)
  })
  useOnClickOutside(filterRef, () => {
    setIsDropdownShow(false)
    setSubmenu2Open(false);
    setSubmenuOpen(false)
    setSubmenu3Open(false)
  })

  const editModal = useMemo(
    () => (
      <EditModal
        isShow={isModalOpen}
        setIsShow={setIsModalOpen}
        value={value}
        setValue={setValue}
        filedName={filedName}
        assessment_id={_id}
        offset={offset}
        limit={limit}
        setAssessmentData={setAssessmentData}
      />
    ),
    [isModalOpen, setIsModalOpen, value, _id, filedName]
  )

  const sendMail = useMemo(
    () => (
      <SendMail isOpen={isShow} setIsOpen={setIsShow} data={assesmentData} />
    ),
    [isShow, setIsShow, assesmentData]
  )


  const handleInchargeUpdate = async (e) => {

    let tempassesmentData = { ...assesmentData }
    tempassesmentData.incharge._id = e.target.value
    setAssessmentData(tempassesmentData)
    let obj = {
      assessment_id: assesmentData._id,
      ['incharge']: e.target.value,
    }
    await dispatch(updateAssessmentById(obj))


    // const obj1 = {
    //   assessment_id: _id,
    //   limit: limit,
    //   offset: offset,
    // }
    // // await dispatch(getQuestionsByQuestionBankId(obj2));
    // const response = await commonService.withToken(
    //   apiConstants.GET_ASSESSMENT_BY_ID,
    //   obj1
    // )
    // if (response?.data && response?.status) {
    //   setAssessmentData(response?.data?.assesment)
    //   // setText(response?.data?.assesment?.assessment_invite_url);
    // }
    // }
  }

  const handleRefresh = async (event) => {
    event.preventDefault()
    const currentPosition = window.pageYOffset;
    setIsLoadingRefresh(true)
    let obj = {
      assessment_url: assessment_url,
      assessment_id: _id,
      offset: offset,
      limit: limit,
      query: search,
      filter: {
        status: filterByStatus,
      },
      filterPerc: filterByPerc,
      filterScore: filterByScore,
      minScore: minScore,
      maxScore: maxScore,
      minPerc: minPerc,
      maxPerc: maxPerc

    }
    await dispatch(getCandidate(obj))
    setIsLoadingRefresh(false)
    setTimeout(() => {
      window.scrollTo(0, currentPosition);
    }, 50);
  }

  const handleShowScore = async (e) => {
    setShowScore(e.target.checked)
    let obj = {
      assessment_id: assesmentData._id,
      showScore: e.target.checked
    }
    await dispatch(updateAssessmentById(obj))
  }
  const handleNegativeMarking = async (e) => {
    setIsNegativeMarking(e.target.checked)
    let obj = {
      assessment_id: assesmentData._id,
      isNegativeMarking: e.target.checked
    }
    await dispatch(updateAssessmentById(obj))
  }


  useEffect(() => {
    if (state?.score === '<50') {
      setShowInputField(true)
      setShowMaxInputField(false)
      setMinPerc(50)
    } else if (state?.score === '>91') {
      //  state?.score === '76 - 90' ?
      setShowInputField(true)
      setShowMaxInputField(false)
      setMinPerc(91)
    } else if (state?.score === '50 - 75') {
      setShowInputField(true)
      setShowMaxInputField(true)
      setMinPerc(50)
      setMaxPerc(75)
    } else if (state?.score === '76 - 90') {
      setShowInputField(true)
      setShowMaxInputField(true)
      setMinPerc(76)
      setMaxPerc(90)
    }

  }, [state?.score])


  if (isLoading) {
    return <Loader />
  }



  // function extractSkillData(skillPercentage) {
  //   const skillObject = skillNames.map((skillName, index) => (
 
     
  //     skillPercentage.map((skill, index) => {
  //       if (skillName === skill.skillName) {
  //         console.log('skill', skill)
  //         return skill.percentage
  //       }
  //     })
      
  //   ))
  //   console.log('skillObject', skillObject)
  // }

  function extractSkillData(skillPercentage) {
    const skillObject = [];
    for (const skillName of skillNames) {
      for (const skill of skillPercentage) {
        if (skillName === skill.skillName) {
          skillObject.push(`${skill.percentage}%`);
          break; // Exit inner loop after finding a match
        }
      }
    }
    // console.log('skillObject', skillObject)
    return skillObject;
  }




  // const skillObject = skillPercentage.map((skill)=>`${skill.percentage}%`)



  const csvData = [
    [t('name'), t('email'), t('appearedDate'), t('status'),`Score(out of ${assesmentData?.totalTestScore})`, t('Percentage'),t('percentile'),  ...skillNames, t('tabsCount')],
    ...candidates.map(({
      candidateName
      , candidateEmail,appearedDate,status,gathered_score, candidatePercentage,percentile , skillPercentage , tabsCount }) => {
      const skillObject = skillPercentage !== 'NA' ? extractSkillData(skillPercentage) : new Array(skillNames.length).fill('NA');
      return [candidateName,
        candidateEmail,
        appearedDate !== 'NA' ? new Date(appearedDate).toLocaleDateString().slice(0, 10) : 'NA',
        status,
        gathered_score,
        candidatePercentage !== 'NA' ? `${candidatePercentage}%` : 'NA',
        percentile,
        ...skillObject,
        tabsCount,
        ]
        
    }),
  ];
  return (
    <div className={`p_five  ${window.screen.height >= 900 ? 'h-screen' : (window.screen.height > 720 && candidates?.length > 5 && !isLoadingRefresh) ? 'h-auto ' : window.screen.height <= 720 && !isLoading ? 'h-auto' : 'h-screen'} bgc-color`}>
      <>
        <Breadcrumbs items={breadcrumbItems} state={state?.offset} />
        <div className="bg-white shadow-sm rounded p-3">
          <div className="flex justify-between flex-row">
            <h6 className="font font-semibold border-b  border-slate-900 text-xs sm:text-xs lg:text-sm md:text-sm xl:text-base 2xl:text-base">
              {t('testInformation')}

            </h6>

            <div className="flex justify-center">
              <div className="text-center font-semibold">
                <FaShareSquare
                  className="text-sm sm:text-sm lg:text-md md:text-sm xl:text-xl 2xl:text-xl  cursor-pointer"
                  onClick={() => setIsShow(true)}
                  title="Share"
                />
              </div>

              {/* <div
                            data-testid="copy-clipboard"
                            className={`text-xs sm:text-xs lg:text-xs md:text-sm xl:text-sm 2xl:text-base text-primary ml-5 px-3 py-1 border-1 border-gray cursor-pointer text-center flex justify-center items-center font-semibold ${isCopy ? 'text-success' : 'text-primary'
                                }`}
                            onClick={copyToClipBoard}
                        >
                            <AiFillCopy className="text-xs sm:text-xs lg:text-xs md:text-sm xl:text-sm 2xl:text-base" /> {` ${isCopy ? t('copied') : t('copy')}`}
                        </div> */}
              {/* <div className="ml-2">
              <Link
                to={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                  assesmentData?.assessment_invite_url
                )}`}
              >
                <BsWhatsapp className="text-success text-2xl" />
              </Link>
            </div> */}
            </div>
          </div>

          {isShare ? (
            <div className="w-full" ref={ref}>
              <div className=" bgc-color shadow w-16 rounded absolute right-3">
                <Link
                  target="_blank"
                  to={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                    assesmentData?.assessment_invite_url
                  )}`}
                >
                  <div className="flex flex-col justify-center items-center">
                    <div className="py-1">
                      <BsWhatsapp className="text-success text-2xl " />
                    </div>
                    <div className="py-1">
                      <FaLinkedin className=" text-2xl text-[#0A66C2] " />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="flex-col justify-between items-center font md:flex-col lg:flex-col xl:flex-row 2xl:flex-row font">
            <div className="flex flex-col md:flex-col lg:flex-col xl:flex-row 2xl:flex-row">

            </div>

            <div className="flex flex-col xl:flex-row xl:justify-between  ">
              <div className="flex flex-col ">
                <div className="flex items-center mt-1">
                  <p className="text-xs sm:text-xs md:text-xs lg:text-xs xl:text-sm 2xl:text-base font-semibold">
                    {t('Test Name')} :
                  </p>
                  <p className="ml-3 text-primary text-xs sm:text-xs md:text-sm lg:text-sm xl:text-text-sm 2xl:text-base">
                    {assesmentData?.assessmentName}
                  </p>

                  <p>
                    <FiEdit
                      className="ml-3 cursor-pointer text-sm sm:text-sm lg:text-md md:text-sm xl:text-lg 2xl:text-xl"
                      onClick={() =>
                        handleEditModalClick(
                          assesmentData?.assessmentName,
                          'assessmentName'
                        )
                      }
                      title='Edit'
                    />
                  </p>
                </div>

                <div className="flex items-center ">
                  <p className="text-xs sm:text-xs md:text-xs lg:text-xs xl:text-sm 2xl:text-base font-semibold">
                    {t('testDuration')}
                  </p>
                  <p className="ml-3 text-primary text-xs sm:text-xs md:text-sm lg:text-sm xl:text-text-sm 2xl:text-base">
                    {assesmentData?.assessment_duration} {t('min')}
                  </p>
                  <p>
                    <FiEdit
                      className="ml-3 cursor-pointer text-sm sm:text-sm lg:text-md md:text-sm xl:text-lg 2xl:text-xl"
                      onClick={() =>
                        handleEditModalClick(
                          assesmentData?.assessment_duration,
                          'assessment_duration'
                        )
                      }
                      title='Edit'
                    />
                  </p>
                </div>

                <div className="flex items-center ">
                  <p className="text-xs sm:text-xs md:text-xs lg:text-xs xl:text-sm 2xl:text-base font-semibold">
                    {t('testScore')} :
                  </p>
                  <p className="ml-3 text-primary text-xs sm:text-xs md:text-sm lg:text-sm xl:text-text-sm 2xl:text-base">
                    {assesmentData?.totalTestScore}
                  </p>
                </div>
                <div className="flex items-center ">
                  <p className="text-xs sm:text-xs md:text-xs lg:text-xs xl:text-sm 2xl:text-base font-semibold">
                    {t('createdBy')} :
                  </p>
                  <p className="ml-3 text-primary text-xs sm:text-xs md:text-sm lg:text-sm xl:text-text-sm 2xl:text-base">
                    {assesmentData?.created_by?.firstName} {assesmentData?.created_by?.lastName}
                  </p>
                </div>
                <div className="flex items-center ">
                  <p className="text-xs sm:text-xs md:text-xs lg:text-xs xl:text-sm 2xl:text-base font-semibold">
                    {t('createdAt')} :
                  </p>
                  <p className="ml-3 text-primary text-xs sm:text-xs md:text-sm lg:text-sm xl:text-text-sm 2xl:text-base">
                    {` ${new Date(assesmentData?.createdAt).getDate()} ${new Date(
                      assesmentData?.createdAt
                    ).toLocaleString('en-US', { month: 'short' })}   ${new Date(
                      assesmentData?.createdAt
                    ).getFullYear()}`}
                  </p>
                </div>

                <div>
                  <p className="text-xs sm:text-xs md:text-xs lg:text-xs xl:text-sm 2xl:text-base font-semibold">
                    {t('Question Banks')} :
                  </p>
                  <ul className="list-disc px-3">
                    {assesmentData?.questionBanksId?.map((value, index) => (
                      <li
                        className="m-0 p-0 text-primary text-xs sm:text-xs md:text-sm lg:text-sm xl:text-text-sm 2xl:text-base"
                        key={index}
                      >
                        {value?.qBankName}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="flex flex-col">
                <div>
                  <label className="mr-3 text-xs sm:text-xs md:text-xs lg:text-xs xl:text-sm 2xl:text-base font-semibold">
                    {t('Assessment Incharge')} :
                  </label>
                  {userData && userData.role?.roleName === 'Assessor' ?
                    <select
                      name="status"
                      value={assesmentData?.incharge?._id}
                      data-testid="plan-input"
                      disabled
                      className="w-44 mt-1 py-1 bg-slate-200 text-slate-500 block border text-sm border-gray-300 rounded-md focus:border-blue-300 focus:outline-blue-300"
                    >
                      <option>{assesmentData?.incharge?.firstName} {assesmentData?.incharge?.lastName}</option>
                    </select>
                    :
                    <select
                      name="status"
                      value={assesmentData?.incharge?._id}
                      data-testid="plan-input"
                      onChange={(e) => handleInchargeUpdate(e)}
                      required
                      className="w-44 mt-1 py-1 cursor-pointer bgc-color  text-primary block border text-sm border-gray-300 rounded-md focus:border-blue-300 focus:outline-blue-300"
                    >
                      {teamsList.map((data) => {
                        return (
                          <option
                            key={data?._id}
                            name={data?._id}
                            value={
                              data?._id === assesmentData?.incharge?._id
                                ? assesmentData?.incharge?._id
                                : data?._id
                            }
                            className="cursor-pointer text-sm"
                          >
                            {data?.firstName} {data?.lastName}
                          </option>
                        )
                      })}
                    </select>}
                </div>

                <div className="mt-4">
                  <p className="font-semibold text-xs sm:text-xs md:text-xs lg:text-xs xl:text-sm 2xl:text-base ">
                    {t('antiCheat')}
                  </p>
                  <ul className="list-disc px-3 text-xs sm:text-xs md:text-xs lg:text-xs xl:text-sm 2xl:text-base">
                    <li>{t('disallow')}</li>
                    <li>{t('makeFullscreen')}</li>
                    <li>{t('logTab')}</li>
                  </ul>
                  {/* <div className=' flex items-center'>
                        <input type='checkbox'  onChange={handleShowScore} checked={showScore} />
                        <label className='ml-3 lg:ml-5 xl:ml-5' >{t('showScore')}</label>
                    </div> */}
                </div>
                <div className="mt-2">
                  <p className="font-semibold text-xs sm:text-xs md:text-xs lg:text-xs xl:text-sm 2xl:text-base ">
                    {t('additionalSettings')} :
                  </p>
                  <div className=' flex items-center'>
                    <input type='checkbox' className='cursor-pointer' onChange={handleShowScore} checked={showScore} />
                    <label className='ml-3 lg:ml-5 xl:ml-5' >{t('showScore')}</label>
                  </div>
                  <div className=' flex items-center'>
                    <input type='checkbox' className='cursor-pointer' onChange={handleNegativeMarking} checked={isNegativeMarking} />
                    <label className='ml-3 lg:ml-5 xl:ml-5' >{t('negativeMarks')}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      {/* candidate =============== */}
      <div className="mt-5 relative">
        <h6 className="font font-semibold">{t('candidatec')}</h6>

        <div className="flex flex-col  ">
          <div className="flex flex-col md:flex-row  w-full">
            <div className="flex  items-center border w-1/2 rounded-lg bg-white">
              <div className="px-2">
                <BiSearch className="text-lg" />
              </div>
              <div className="flex items-center">
                <input
                  type="text"
                  data-testid="search-input"
                  placeholder={t('search')}
                  value={search}
                  className="bg-white mb-1 py-1 sm:mb-0 border-none focus:border-none focus:outline-none w-full"
                  onChange={(e) => {
                    setSearch(e.target.value)
                    setFilterByStatus('')
                  }}
                />

              </div>

            </div>
            <div className='flex xs:flex-col sm:flex-row '>
              <div className='flex  '>
                <div className="relative inline-block text-left ml-1">
                  <button
                    onClick={() => setIsDropdownShow(!isDropdownShow)}
                    className="dropdown-toggle xs:mt-2 md:mt-0 font-bold block px-3  border-2 text-xs sm:text-sm  h-10 rounded-md border-blue-300 hover:border-green-200 outline-blue-300 bgc-color"
                  >
                    Filters
                  </button>
                  {isDropdownShow && (
                    <div ref={filterRef} className="absolute mt-2 w-24 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                      <ul className="py-1 text-gray-700 px-0 mb-0" >
                        <li className="relative ">
                          <button
                            onClick={() => {
                              setSubmenu2Open(false)
                              setSubmenu3Open(false)
                              setSubmenuOpen(!submenuOpen)
                            }}
                            className="w-full pl-2 block py-1  text-sm font-semibold hover:bg-blue-100 focus:bg-blue-100 flex items-center"
                          >
                            By Status
                            <div className='flex items-center font-semibold ml-1'>
                              <svg
                                className="w-3 h-3"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path className='flex items-center'
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M9 5l7 7-7 7"
                                />
                              </svg>
                            </div>
                          </button>
                          {submenuOpen && (
                            <div className="ml-0.5 absolute left-full top-0 mt-0 w-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                              <ul className="py-1 text-gray-700 p-0 cursor-pointer">
                                {
                                  status?.map((value, index) => (
                                    <>
                                      <li key={index}
                                        className=" text-sm hover:bg-blue-100 flex justify-left pl-4 "
                                        onClick={() => handleFilterByStatus(value.status === 'All' ? '' : value.status)}>

                                        {value?.status}
                                      </li>
                                    </>
                                  ))}
                              </ul>
                            </div>
                          )}
                        </li>
                        <li className="relative flex items-center">
                          <button
                            onClick={() => {
                              setSubmenuOpen(false)
                              setSubmenu3Open(false)
                              setSubmenu2Open(!submenu2Open)
                            }}
                            className="w-full pl-2 block py-1 text-sm font-semibold hover:bg-blue-100 focus:bg-blue-100 flex items-center"
                          >
                            By Percent
                            <div className='flex items-center font-semibold ml-1'>

                              <svg
                                className="w-3 h-3 flex items-center"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M9 5l7 7-7 7"
                                />
                              </svg>
                            </div>
                          </button>
                          {submenu2Open && (
                            <div className="ml-0.5 absolute left-full top-0 mt-0 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                              <ul className="py-1 text-gray-700 p-0 cursor-pointer ">
                                {
                                  percStatus?.map((value, index) => (
                                    <li key={index}
                                      className="flex justify-left px-4 text-sm hover:bg-blue-100 pl-4"
                                      onClick={() => handleFilterByPerc(value.status)}>

                                      {value?.status}
                                    </li>))}
                              </ul>
                            </div>
                          )}
                        </li>
                        <li className="relative flex items-center">
                          <button
                            onClick={() => {
                              setSubmenuOpen(false)
                              setSubmenu2Open(false)
                              setSubmenu3Open(!submenu3Open)
                            }}
                            className="w-full pl-2 block py-1 text-sm font-semibold hover:bg-blue-100 focus:bg-blue-100 flex items-center"
                          >
                            By Score
                            <div className='flex items-center font-semibold ml-1'>

                              <svg
                                className="w-3 h-3 flex items-center"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M9 5l7 7-7 7"
                                />
                              </svg>
                            </div>
                          </button>
                          {submenu3Open && (
                            <div className="ml-0.5 absolute left-full top-0 mt-0 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                              <ul className="py-1 text-gray-700 p-0 cursor-pointer ">
                                {
                                  scoreStatus?.map((value, index) => (
                                    <li key={index}
                                      className="flex justify-left px-4 text-sm hover:bg-blue-100 pl-4"
                                      onClick={() => handleFilterByScore(value.status)}>

                                      {value?.status}
                                    </li>))}
                              </ul>
                            </div>
                          )}
                        </li>

                      </ul>
                    </div>
                  )}
                </div>

                {showInputField &&
                  <div className='flex items-center w-20  rounded-md bg-white ml-2 xs:mt-2 md:mt-0'>
                    <div className='flex items-center'>
                      <input

                        type='number'
                        placeholder='%:min'
                        defaultValue={minPerc}
                        onChange={(e) => setMinPerc(e.target.value)}
                        className="bg-white mb-1 pl-1 py-1 sm:mb-0  focus:border-none focus:outline-none rounded-md w-full"
                      ></input>
                    </div></div>}
                {showMaxInputField &&
                  <div className='flex items-center w-20  rounded-md bg-white ml-2 xs:mt-2 md:mt-0'>

                    <div className='flex items-center p'>
                      <input
                        type='number'
                        placeholder='%:max'
                        value={maxPerc}
                        onChange={(e) => setMaxPerc(e.target.value)}
                        className="bg-white mb-1  pl-1 py-1 sm:mb-0  focus:border-none focus:outline-none rounded-md w-full"
                      ></input>
                    </div>
                  </div>}
                {showInputFieldScore &&
                  <div className='flex items-center w-24  rounded-md bg-white ml-2 xs:mt-2 md:mt-0'>
                    <div className='flex items-center'>
                      <input

                        type='number'
                        placeholder='Score:min'
                        value={minScore}
                        onChange={(e) => setMinScore(e.target.value)}
                        className="bg-white mb-1 pl-1 py-1 sm:mb-0  focus:border-none focus:outline-none rounded-md w-full"
                      ></input>
                    </div></div>}
                {showMaxInputFieldScore &&
                  <div className='flex items-center w-24  rounded-md bg-white ml-2 xs:mt-2 md:mt-0'>

                    <div className='flex items-center p'>
                      <input
                        type='number'
                        placeholder='Score:max'
                        value={maxScore}
                        onChange={(e) => setMaxScore(e.target.value)}
                        className="bg-white mb-1  pl-1 py-1 sm:mb-0  focus:border-none focus:outline-none rounded-md w-full"
                      ></input>
                    </div>
                  </div>}
              </div>
              <div className='flex items-center'>
                {filterByStatus &&
                  <div className="z-0 relative inline-block text-left ml-1 flex items-center">

                    <button

                      className="flex justify-center xs:mt-2 sm:mt-0 items-center font-medium block  px-3 border text-xs   h-6 rounded-full  bg-slate-300"
                    >
                      {filterByStatus}
                      <span className='pl-2 ' onClick={(e) => setFilterByStatus('')}><ImCross />
                      </span>
                    </button>
                  </div>
                }
                {filterByPerc &&
                  <div className="z-0relative inline-block text-left ml-1 flex items-center">

                    <button

                      className="flex justify-center w-32 xs:mt-2 sm:mt-0  items-center font-medium block  px-3 border text-xs h-6 rounded-full bg-slate-300"
                    >
                      {filterByPerc}
                      <span className='pl-2' onClick={(e) => {
                        handleFilterByPerc('');
                        setShowInputField(false);
                        setShowMaxInputField(false);
                      }}> <ImCross />
                      </span>

                    </button>
                  </div>
                }
                {filterByScore &&
                  <div className="z-0relative inline-block text-left ml-1 flex items-center">

                    <button

                      className="flex justify-center w-32 xs:mt-2 sm:mt-0  items-center font-medium block  px-3 border text-xs h-6 rounded-full bg-slate-300"
                    >
                      {filterByScore}
                      <span className='pl-2' onClick={(e) => {
                        handleFilterByScore('');
                        setShowInputFieldScore(false);
                        setShowMaxInputFieldScore(false);
                      }}> <ImCross />
                      </span>

                    </button>
                  </div>
                }
              </div>
            </div>



          </div>

          <div className="flex items-center  mt-4 xl:pt-0 w-full xl:w-1/2 2xl:w-1/2">
            <div className='flex items-center mr-2'>
              <CSVLink className="font-bold text-black w-full px-3 pt-1.5 border-2 text-xs sm:text-sm  h-10 rounded-md border-blue-300 hover:border-green-200 focus:outline-blue-300 bgc-color no-underline" filename="my-file.csv" data={csvData}>
                Export to CSV
              </CSVLink>
            </div>

            
          {selectedIds?.length > 0 && (
          <div className="flex justify-center items-center ml-5">
            <button
              type="button"
              onClick={handleSubmitBulkDelete}
              className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm w-28 py-2 text-center"
            >
              {isBulkDelete ? (
                <span className="spinner-border spinner-border-sm text-light"></span>
              ) : (
                'Delete Selected'
              )}
            </button>
          </div>
        )}


            <div className="flex items-center ml-2">
              <img
                src={images.REFRESH}
                className="w-14 py-1 bgc-color hover:border-green-200 rounded cursor-pointer border-2 border-blue-300"
                alt="refresh"
                onClick={handleRefresh}
                title='Refresh'
              />
            </div>
          </div>
        </div>


        <div className="pb-3">
          {isLoadingRefresh &&
            <div >
              <div
                className="spinner-border text-primary absolute top-50 z-1 "
                style={{ left: '50%', bottom: '20%' }}
              ></div>
            </div>}
          <DataTable
            columns={columns}
            data={candidates}
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            handleRowClick={handleRowClick}
            offset={offset}
            handleLimit={handleLimit}
          />

        </div>
      </div>
      {isShowBulkDelete ?
     
          <DeleteAssessment
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setSelectedIds={handleSelectedIds}
            setIsShowBulkDelete={isShowBulkDelete}
             data={{ candidates: selectedIds,assementId: _id }}
          />
:
<DeleteAssessment
isOpen={isOpen}
setIsOpen={setIsOpen}
data={{ candidateId: candidateId, assementId: _id }}
/>
        
      }
     

      {sendMail}
      {editModal}
    </div>
  )
}
export default AssessmentDetails
