/* eslint-disable react-hooks/exhaustive-deps */
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'
import { formatedDate, formattedDateTime } from '../../Utils/Index'
import { t } from 'i18next'
import logoImage from '../../logo.png'
import { useEffect, useState } from 'react'
import html2canvas from 'html2canvas'

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'row',
  },
  section: {
    margin: 10,
    padding: 10,
  },
  header: {
    fontSize: '12px',
    width: '100%',
    marginLeft: '10px',
    marginTop: '15px',
  },
  subHeading: {
    fontSize: '12px',
    width: '100%',
    marginTop: '15px',
    marginLeft: '20px',
  },
  label: {
    fontSize: 10,
    fontWeight: '500',
    marginTop: '10px',
    color: 'gray',
  },
  text: {
    fontSize: 10,
    marginTop: '10px',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    margin: '5px',
    width: '100%',
  },
  table: {
    width: '100%',
    marginTop: '10px',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '10%',
  },
  cell: {
    width: '50%',
    padding: 5,
  },
  image: {
    width: '150px',
    height: '70px',
    marginBottom: 10,
  },
  heading: {
    textAlign: 'center',
    marginTop: '15px',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  felxCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingVertical: '10px',
  },
  innerFlex: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  line: {
    borderBottom: 1,
    borderBottomColor: 'gray',
    marginTop: '5px',
  },
  flexColumnLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '50%',
  },
  flexColumnRight: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '50%',
    borderLeft: 1,
    borderColor: 'gray',
  },
  circleContainer: {
    width: '200px',
    height: '200px',
    position: 'relative',
  },
  circleBackground: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    backgroundColor: 'lightgray', // Background color for the entire circle
  },
  circleProgressBar: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
    backgroundColor: 'green', // Color for the progress bar
  },
  percentage: {
    fontSize: 24,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
    color: 'white',
  },
  evaluationFlex: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginLeft: '10px',
  },
  skillFlex: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingHorizontal: '30px',
  },
  imagePage: {
    width: '100%',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center', // Center the image on the page
  },
  divImg: {
    width: '100%',
    height: '100vh',
  },
  skillChartDiv: {
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    height: '300px',

    // height: '250px'
  },
  scoreChartImgUrl: {
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  question: {
    backgroundColor: '#f2f2f2', // Light gray background for questions
    padding: 10,
    marginBottom: 10, // Add spacing between questions
  },
  questionTitle: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  questionType: {
    fontStyle: 'italic',
    fontSize: 10, // Slightly smaller for question type
  },
  notAttempted: {
    color: 'red',
    fontSize: 10,
  },
  description: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 10,
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%'
    // marginBottom: 5,
  },
  optionText: {
    fontSize: 10,
    marginLeft: 5,
  },
  correctAnswer: {
    // Simulate green background for correct answer
    backgroundColor: '#c3f0c3',
  },
  incorrectAnswer: {
    // Simulate red background for incorrect answer
    backgroundColor: '#f9d2d2',
  },
  optionBorder: {
    border: '2px solid lightgray',
    padding: '5px',
  },
})

const PdfDocument = ({
  candidateData,
  testData,
  divRef,
  skillChartRef,
  scoreChartRef
}) => {
  const [skillChartImgUrl, setSkillChartImgUrl] = useState(null)
  const [divImgUrl, setDivImgUrl] = useState(null)
  const [scoreChartImgUrl, setScoreChartImgUrl] = useState(null)
  useEffect(() => {
    const captureDivImage = async () => {
      try{
        if (divRef?.current && skillChartRef?.current && scoreChartRef?.current) {
          // Log to confirm element existence
          const canvas = await html2canvas(divRef?.current)
          const canvasForSkillChart = await html2canvas(skillChartRef?.current)
          const canvasForScoreChart = await html2canvas(scoreChartRef?.current)
          // const canvasForScoreChart =  await html2canvas(scoreChartRef?.current)
          const imageData = canvas.toDataURL('image/png')
          const skillChartImgData = canvasForSkillChart.toDataURL('image/png')
          const scoreChartImgData = canvasForScoreChart.toDataURL('image/png')
          setDivImgUrl(imageData)
          setSkillChartImgUrl(skillChartImgData)
          setScoreChartImgUrl(scoreChartImgData)
        } else {
          console.error('Div element not found') // Log if element is missing
        }
      }catch(err){
        console.error('error', err)
      }
    }

    captureDivImage() // Call after div is rendered
  }, [divRef.current, skillChartRef.current])
  return (
    <Document>
      <Page size="A4">
        <View style={styles.section}>
          <View style={styles.rowContainer}>
            <View style={styles.flexColumn}>
              {/* <Text >{t('testainty')}</Text>
                            <Text style={styles.label}>
                                Elevate Assessment, Illuminate Potential
                            </Text> */}
              <Image style={styles.image} src={logoImage} />
            </View>
          </View>

          <View style={styles.flexCenter}>
            <Text style={styles.heading}>{t('assessmentReport')}</Text>
          </View>
          <View style={styles.line}></View>

          <View>
            <Text style={styles.header}>{t('candidateDetails')}</Text>
          </View>

          <View style={styles.flexRow}>
            <View style={styles.innerFlex}>
              <View>
                <Text style={styles.label}>{t('namec')}</Text>
                <Text style={styles.label}>{t('emailc')} </Text>
                <Text style={styles.label}>{t('assessmentNamec')} </Text>
                <Text style={styles.label}>{t('durationc')}</Text>
              </View>
              <View>
                <Text style={styles.text}>{candidateData.candidateName}</Text>
                <Text style={styles.text}>{candidateData.candidateEmail}</Text>
                <Text style={styles.text}>{candidateData.AssessmentName}</Text>
                <Text style={styles.text}>
                  {candidateData.AssessmentDuration} {t('min')}
                </Text>
              </View>
            </View>
            <View style={styles.innerFlex}>
              <View>
                <Text style={styles.label}>{t('appearedOnc')} </Text>
                <Text style={styles.label}>{t('startedAtc')}</Text>
                <Text style={styles.label}>{t('completedAtc')} </Text>
              </View>
              <View>
                <Text style={styles.text}>
                  {formatedDate(candidateData.StartedAt)}
                </Text>
                <Text style={styles.text}>
                  {formattedDateTime(candidateData.StartedAt)}
                </Text>
                <Text style={styles.text}>
                  {formattedDateTime(candidateData.CompletedAt)}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.line}></View>
          <View style={styles.flexRow}>
            <View style={styles.flexColumnLeft}>
              <Text style={styles.header}>{t('evaluation')}</Text>
              <View style={styles.evaluationFlex}>
                <View>
                  <Text style={styles.label}>{t('totalPercentage')}</Text>
                </View>
                <View>
                  <Text style={styles.text}>
                    {candidateData.Percentage}
                    {t('percente')}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.flexColumnRight}>
              <Text style={styles.subHeading}>{t('skillReport')}</Text>
              {testData && testData.labels && testData.labels.length && (
                <>
                  {testData.labels.map((label, index) => (
                    <View style={styles.skillFlex} key={index}>
                      <View>
                        <Text style={styles.label}>{label} :</Text>
                      </View>
                      <View>
                        <Text style={styles.text}>
                          {testData.data[index]}
                          {t('percente')}
                        </Text>
                      </View>
                    </View>
                  ))}
                </>
              )}
            </View>
          </View>
          <View style={styles.line}></View>
          <View >
          {skillChartImgUrl && (
            <View style={styles.felxCenter}>
              <Image
                src={skillChartImgUrl}
                style={styles.skillChartDiv}
                alt="Section Image"
              />
            </View>
          )}
          {/* {imgUrl && 
          <Image
          src={imgUrl}
          // style={styles.skillChartDiv}
          alt="Section Image"
        />
          } */}
          {scoreChartImgUrl && (
            <View style={styles.felxCenter}>
              <Image
                src={scoreChartImgUrl}
                style={styles.scoreChartImgUrl}
                alt="Section Image"
              />
            </View>
          )}
          </View>
        </View>
      </Page>
      <Page size="A4">
        {divImgUrl && (
          <View style={styles.divImg}>
            <Image src={divImgUrl} alt="Section Image" />
          </View>
        )}



        {/* {Object.entries(testLogsData).map(([key, value], index) => (
          // eslint-disable-next-line react/jsx-key
          <View style={styles.section}>
            <Text key={index}>{key}</Text>
            {value.map((list, listIndex) => (
              <View key={listIndex}>
                <Text style={styles.questionTitle}>
                  {listIndex + 1}. {list.questionTitle}
                </Text>
                <Text style={styles.questionType}>
                  {list.qType === 'MCQ' && '( Multiple Correct)'}
                </Text>
                {!list?.candidateAnswer && (
                  <Text style={styles.notAttempted}>( Not Attempted )</Text>
                )}
                {list?.questiondesc && (
                  <Text style={styles.description}>
                    {list.questiondesc.replace(/\n/g, '\n')}
                  </Text>
                )}

                {list?.options && (
                  <View>
                    {list?.options.map((option, optionIndex) => {
                      const optionKey = Object.keys(option)[0]
                      const isCorrect = optionKey === list?.correctAnswer[0]
                      const isCandidateAnswer =
                        optionKey === list?.candidateAnswer

                      return (
                        <View style={styles.optionContainer} key={optionIndex}>
                          {isCorrect && <View style={styles.correctAnswer} />}
                          {isCandidateAnswer && !isCorrect && (
                            <View style={styles.incorrectAnswer} />
                          )}
                          <View style={styles.optionBorder}>
                            <Text style={styles.optionText}>
                              {option[optionKey]}
                            </Text>
                          </View>
                        </View>
                      )
                    })}
                  </View>
                )}
              </View>
            ))}
          </View>
        ))} */}
      </Page>
    </Document>
  )
}

export default PdfDocument
